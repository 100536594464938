@import 'utils/includes.scss';

.Button {
    font-family: "Helvetica Neue";
    @include smooth-transition();
    display: inline-block;
    text-decoration: none;
    line-height: 1em;
    cursor: pointer;
    border-radius: 2px;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    &:hover {
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, .1);
    }
    // box-shadow: 0 8px 32px 0 rgba(0, 0, 0, .1);
    // &:hover {
    //     box-shadow: 0 16px 54px 0 rgba(0, 0, 0, .1);
    // }
}

.Button-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.Button-full {
    width: 100%;
}

.Button-sm {
    padding: 8px 12px;
}

.Button-md {
    padding: 12px 16px;
}

.Button-lg {
    padding: 20px 44px;
    font-size: 16px;
}


.Button-default {
    border: 1px solid $primary-300;
    background-color: $primary-300;
    color: $white;
    &:active {
        border: 1px solid $primary-400;
        background-color: $primary-400;
    }
}

.Button-primary {
    border: 1px solid $primary-300;
    background-color: $primary-300;
    color: $white;
    font-weight: bold;
    &:active {
        border: 1px solid $primary-400;
        background-color: $primary-400;
    }
}



.Button-secondary {
    border: 1px solid $secondary-300;
    background-color: $secondary-300;
    color: $white;
    font-weight: bold;
    &:active {
        border: 1px solid $secondary-400;
        background-color: $secondary-400;
    }
}

.Button-success {
    border: 1px solid $success-300;
    background-color: $success-300;
    color: $white;
    font-weight: bold;
    &:active {
        border: 1px solid $success-400;
        background-color: $success-400;
    }
}

.Button-alert {
    border: 1px solid $alert-300;
    background-color: $alert-300;
    color: $white;
    font-weight: bold;
    &:active {
        border: 1px solid $alert-400;
        background-color: $alert-400;
    }
}